import router from '@/router'
import useWindow from '@/hooks/useWindow'

const DEFAULT_OPTIONS = {
  target: '_blank',
}

export default (destination, _options = {}, allow = []) => {
  if (!destination) return null
  if (!useWindow()) return null

  let link = String(destination)
  const isRelative = typeof destination === 'string' && destination[0] === '/'

  const resolved = router.resolve(destination)
  if ((isRelative || typeof destination === 'object') && resolved) {
    link = resolved.fullPath || link
  }

  if (typeof link !== 'string') return null

  if (allow?.length && allow !== '*' && !isRelative) {
    const { host } = new URL(link)
    const found = allow.find(hostAllowed => hostAllowed === host)
    if (!found) {
      console.warn('Redirect not allowed to host:', host, { link })
      return null
    }
  }

  const options = Object.assign({}, DEFAULT_OPTIONS, _options || {})
  const element = document.createElement('a')

  for (var attr in options) {
    element.setAttribute(attr, options[attr])
  }

  element.href = link
  element.target = options.target
  element.style.height = '1px'
  element.style.width = '1px'

  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)

  return link
}
