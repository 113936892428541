<template>
  <div class="flex justify-between items-center text-sm">
    <div class="flex gap-2 items-center" v-if="limit > 0 && !hideLimits">
      <label for="limit">Rijen per pagina:</label>
      <select
        name="limit"
        id="limit"
        class="border"
        :disabled="disabled"
        :value="limit"
        @input="emitLimit($event.target.value)"
      >
        <option v-for="limit in LIMITS" :key="limit" :value="limit">{{ limit }}</option>
      </select>
    </div>
    <div class="hidden md:flex gap-1 items-center" v-if="count > 0">
      <span>Rijen</span>
      <template v-if="limit > 1">
        <span>{{ Math.min(count, (innerPage - 1) * limit + 1) }}</span>
        <span>-</span>
      </template>
      <span>{{ Math.min(count, innerPage * limit) }}</span>
      <span>van</span>
      <span>{{ count }}</span>
    </div>
    <div class="flex gap-4 items-center">
      <button class="inline-flex w-2" :disabled="disabled || innerPage <= 1" @click="innerPage -= 1">
        <i
          :class="{
            'fas fa-spinner animate-spin': disabled,
            'fas fa-angle-left': !disabled,
          }"
        ></i>
      </button>
      <span class="input cursor-pointer" @click.prevent.stop="handleLongPagination" v-if="maxPages > 1000">Page {{ innerPage }} / {{ maxPages }}</span>
      <select v-else name="page" id="page" class="animate-none transition-none w-32 border pr-1 js-choice" :disabled="disabled" v-model="innerPage">
          <option v-for="p in maxPages" :key="p" :value="p">{{ labelPagina }} {{ p }}</option>
      </select>
      <button class="inline-flex w-2" :disabled="disabled || innerPage >= maxPages" @click="innerPage += 1">
        <i
          :class="{
            'fas fa-spinner animate-spin': disabled,
            'fas fa-angle-right': !disabled,
          }"
        ></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import useModal from '@/hooks/useModal'
import { useVModel } from '@vueuse/core'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

const LIMITS = [5, 10, 15, 20, 25, 30, 50]
const props = defineProps({
  hideLimits: Boolean,
  labelPagina: {
    type: String,
    default: 'Pagina',
  },
  count: {
    type: [Number, String],
    default: 0,
  },
  page: {
    type: [Number, String],
    default: 1,
  },
  limit: {
    type: [Number, String],
    default: 0,
    required: true,
  },
  offset: {
    type: [Number, String],
    default: 0,
    required: true,
  },
})
const emit = defineEmits(['update:page', 'update:offset', 'update:limit'])

const [openFixedModal] = useModal({ fixed: true })

const isMounted = ref(false)
onMounted(() => {
  nextTick(() => isMounted.value = true)
})

// const page = ref(1)
const innerPage = useVModel(props, 'page', emit, { passive: true })
const disabled = ref(false)
const maxPages = computed(() => {
  const pages = Math.ceil(parseInt(props.count || 0, 10) / parseInt(props.limit || 5, 10))
  return Math.max(1, pages)
})
const offset = computed(() => {
  return Math.max(0, parseInt(innerPage.value - 1, 10) * parseInt(props.limit || 5, 10))
})

const disabledDelayed = () => {
  disabled.value = true
  setTimeout(() => {
    disabled.value = false
  }, 360)
}
const emitOffset = () => {
  disabledDelayed()
  emit('update:offset', offset.value)
}
const emitLimit = limit => {
  disabledDelayed()
  if (innerPage.value > 1) {
    innerPage.value = 1
  }
  emit('update:limit', Number(limit))
}
const handleLongPagination = () => {
  const callback = (newPage) => innerPage.value = newPage
  openFixedModal('FORM_LONG_PAGINATION', { maxPages: maxPages.value, page: innerPage.value, callback })
}

watch(() => offset.value, emitOffset)
watch(() => innerPage.value, emitOffset)
onMounted(emitOffset)
</script>

<style scoped lang="scss">
button:disabled {
  @apply text-gray-400 cursor-not-allowed;
}
</style>
