<template>
  <span style="font-size: 75%" class="bg-blue-50 border border-gray-300 rounded px-1 flex flex-row items-center gap-1">
    <i class="text-gray-500 fas fa-envelope"></i>
    <strong class="pr-1" :class="labelClass(sjabloon)">{{ labelVerstuurd(sjabloon).trim() }}</strong>
    <span v-if="log" class="italic">({{ daysAgo }} d.)</span>
  </span>
</template>

<script setup>
import { computed } from 'vue'
import { dateString } from '@/functions/formatDate'

const props = defineProps({
  sjabloon: String,
  log: Object,
})

const daysAgo = computed(() => Math.ceil((new Date(dateString()) - new Date(dateString(props.log?.time))) / 86400000))

const labelVerstuurd = (sjabloon) => {
  if (sjabloon === 'FACTUUR') return 'Initieel verstuurd'
  if (sjabloon === 'FACTUUR_EERSTE_HERINNERING') return 'Her. #1 verstuurd'
  if (sjabloon === 'FACTUUR_TWEEDE_HERINNERING') return 'Her. #2 verstuurd'
  if (sjabloon === 'FACTUUR_ADVOCAAT') return 'Advocaat verstuurd'
  return 'Nog niet verstuurd'
}

const labelClass = (sjabloon) => {
  if (sjabloon === 'FACTUUR') return 'text-green-500'
  if (sjabloon === 'FACTUUR_EERSTE_HERINNERING') return 'text-yellow-500'
  if (sjabloon === 'FACTUUR_TWEEDE_HERINNERING') return 'text-orange-500'
  if (sjabloon === 'FACTUUR_ADVOCAAT') return 'text-orange-800'
  return 'text-red-500'
}
</script>
